import MobileHeader from '@src/components/MobileHeader';
import PlayLayout from '@src/components/PlayLayout';
import { styled } from 'stitches.config';
import LeftIcon from '@assets/icons/leading-icn.svg';
import { navigate } from 'gatsby';
import { usePlayroomContext } from '@src/providers/Playroom';
import { useGetRoomQuery } from '@src/apis/queries';
import { roomStatusToLabel } from '@src/constants/playroom';
import { LoadingSpinner } from '@src/components/LoadingSpinner';
import Modal, { useModal } from '@src/components/Modal';
import { ConfirmLeaveDuringPlayModal } from '@src/components/Modal/play/ConfirmLeaveDuringPlayModal';
import { useAgora } from '@src/hooks/useAgora';
import { useToastContext } from '@src/providers/Toast';

interface PlayroomInfoProps {
  params: {
    id: string;
  };
}

export default function PlayroomInfo(props: PlayroomInfoProps) {
  const { openSheet, currentRoomData, isHost } = usePlayroomContext();
  const { showToast } = useToastContext();
  const {
    agoraContextData: { uid, handleJoinOrLeave },
  } = useAgora();

  const { id } = props.params;

  const { data: roomData, isLoading } = useGetRoomQuery(id, true);
  const { openModal, closeModal, modal } = useModal();

  const handlePrevClick = () => {
    navigate('/play/rooms');
    openSheet();
  };

  const handleLeaveClick = () => {
    openModal(
      <ConfirmLeaveDuringPlayModal
        onClose={closeModal}
        onConfirm={() => {
          closeModal();
          navigate('/play/rooms');

          handleJoinOrLeave();

          showToast('플레이룸에서 나갔습니다.');
        }}
        isHost={isHost}
      />
    );
  };

  return (
    <>
      <PlayLayout>
        <MobileHeader
          left={
            <Button onClick={handlePrevClick}>
              <img src={LeftIcon} alt="뒤로 가기 아이콘" />
            </Button>
          }
          center="플레이룸 정보"
          right={
            isHost && (
              <Button onClick={() => navigate('setting')}>
                <ModifyText>수정</ModifyText>
              </Button>
            )
          }
        />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <InfoContainer>
              <InfoItem>
                <InfoTitle>플레이룸 제목</InfoTitle>
                <InfoContent>{roomData?.data?.title}</InfoContent>
              </InfoItem>
              <InfoItem>
                <InfoTitle>플레이할 게임</InfoTitle>
                <InfoContent>{roomData?.data?.roomProject.name}</InfoContent>
              </InfoItem>
              <InfoItem>
                <InfoTitle>방장 정보</InfoTitle>
                <InfoContent>
                  {
                    roomData?.data?.joinedUserList.find(
                      (user) => user.role === 'Master'
                    )?.name
                  }
                </InfoContent>
              </InfoItem>
              <InfoItem>
                <InfoTitle>인원 수</InfoTitle>
                <InfoContent>{roomData?.data?.maxUserCount}</InfoContent>
              </InfoItem>
              <InfoItem>
                <InfoTitle>모집 상태</InfoTitle>
                <InfoContent>
                  {roomStatusToLabel[roomData?.data?.status || 'Waiting']}
                </InfoContent>
              </InfoItem>
              {isHost && (
                <InfoItem>
                  <InfoTitle>비밀번호</InfoTitle>
                  <InfoContent>
                    {roomData?.data?.password || '없음'}
                  </InfoContent>
                </InfoItem>
              )}
            </InfoContainer>
            <LeaveButtonContainer onClick={handleLeaveClick}>
              <LeaveButton>플레이룸 나가기</LeaveButton>
            </LeaveButtonContainer>
          </>
        )}
      </PlayLayout>
      <Modal {...modal} />
    </>
  );
}

const Button = styled('button', {
  all: 'unset',
  cursor: 'pointer',
});

const ModifyText = styled('span', {
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '22px',
  color: '#c869ff',
});

const InfoContainer = styled('div', {});

const InfoItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '18px 16px',
  fontSize: '14px',
  fontWeight: 400,
  lineHeight: '20px',
});

const InfoTitle = styled('div', {
  color: '#191919',
});

const InfoContent = styled('div', {
  color: '#A7A8A9',
});

const LeaveButtonContainer = styled('div', {
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  padding: '8px 16px',
  borderTop: '1px solid #ebeced',
});

const LeaveButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  padding: '13px',
  border: '1px solid #c869ff',
  borderRadius: '999px',
  color: '#c869ff',
  backgroundColor: 'transparent',
  cursor: 'pointer',
});
